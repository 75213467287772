import React, { useEffect, useState } from "react";
import * as S from "./StampedPlugin.styles";

const StampedPlugin = props => {
  const {
    publicAPIKey,
    storeUrl,
    productId,
    productName,
    productUrl,
    productImage,
    productDescription,
    productSku
  } = props;

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      const script = document.createElement("script");
      script.src = "https://cdn1.stamped.io/files/widget.min.js";
      script.async = true;
      script.onload = () => {
        window.StampedFn.init({ apiKey: publicAPIKey, storeUrl: storeUrl });
      };
      document.body.appendChild(script);

      function addEventListenerStamped(el, eventName, handler) {
        if (el.addEventListener) {
          el.addEventListener(eventName, handler);
        } else {
          el.attachEvent("on" + eventName, function() {
            handler.call(el);
          });
        }
      }
      addEventListenerStamped(document, "stamped:reviews:loaded", function(e) {
        if (!document.getElementById("stamped-widget-logo999")) {
          let logoDiv = document.createElement("div");
          let logoDivImg = document.createElement("img");
          logoDiv.setAttribute("class", "stamped-widget-logo");
          logoDiv.setAttribute("id", "stamped-widget-logo999");
          logoDivImg.setAttribute(
            "src",
            "https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Petlab_logo_circle.png?v=1632388569"
          );
          logoDivImg.setAttribute("alt", "PetLab Co. Logo");
          logoDiv.append(logoDivImg);
          document.querySelector(".summary-overview").prepend(logoDiv);
        }
      });
    }
    return () => setLoaded(true);
  }, [loaded, publicAPIKey, storeUrl]);

  return (
    <S.Container>
      <div
        id="stamped-main-widget"
        data-product-id={productId}
        data-name={productName}
        data-url={productUrl}
        data-image-url={productImage}
        data-description={productDescription}
        data-product-sku={productSku}
      ></div>
    </S.Container>
  );
};

export default StampedPlugin;
